import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/circleci/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "tech-debt"
    }}>{`Tech Debt`}</h1>
    <p>{`Here is an assortment of known technical debt that should be addressed.`}</p>
    <h2 {...{
      "id": "jsx-ast-flavor"
    }}>{`JSX AST Flavor`}</h2>
    <p>{`Stele currently detects, processes, and possibly changes JSX after the JSX has
been compiled to `}<inlineCode parentName="p">{`React.createElement`}</inlineCode>{` calls. Here are some reasons why that is
not ideal, in no particular order:`}</p>
    <ul>
      <li parentName="ul">{`In Babel, plugin order matters. This means Stele must be run after JSX
transforms have occurred, and no sooner.`}</li>
      <li parentName="ul">{`Since both JSX usages and dunder function usages end up as call expressions,
it becomes harder to disentangle the two parts visually and conceptually.`}</li>
      <li parentName="ul">{`Within the React ecosystem, there is a
`}<a parentName="li" {...{
          "href": "https://babeljs.io/blog/2020/03/16/7.9.0#a-new-jsx-transform-11154-https-githubcom-babel-babel-pull-11154"
        }}>{`planned migration that deprecates `}<inlineCode parentName="a">{`React.createElement`}</inlineCode></a>{`.
Although not an immediate concern, this means the current architecture is
not future-proof.`}</li>
    </ul>
    <p>{`Removing this tech debt involves rewriting Stele to deal with JSX nodes directly
in tasks such as extractability detection, ICU string extraction, and tree
replacement.`}</p>
    <p>{`For TypeScript users, this means that the compiler option `}<inlineCode parentName="p">{`jsx`}</inlineCode>{` will have to be
set to `}<inlineCode parentName="p">{`"preserve"`}</inlineCode>{` instead of `}<inlineCode parentName="p">{`"react"`}</inlineCode>{`, further making it necessary to enable
the `}<inlineCode parentName="p">{`@babel/plugin-transform-react-jsx`}</inlineCode>{` plugin.`}</p>
    <h3 {...{
      "id": "decoupling"
    }}>{`Decoupling`}</h3>
    <p>{`Gauging the kind of extraction (`}<inlineCode parentName="p">{`extractabilityOfExpression`}</inlineCode>{`) applicable and
performing the actual extraction are two phases that are deliberately decoupled
at the moment. This decoupling is unnecessary: when a function call is
recognized as a dunder usage, a message is always extracted from that call. The
same applies to JSX expressions. Continuing this decoupling creates extra work
for the extraction phase itself, work that has already been performed by the
gauging phase.`}</p>
    <p>{`Knowing this, the `}<inlineCode parentName="p">{`extractability`}</inlineCode>{` module should be collapsed into the
`}<inlineCode parentName="p">{`extractors`}</inlineCode>{` module, resulting in two primary functions, tentatively named
`}<inlineCode parentName="p">{`extractFromJSXIfValid`}</inlineCode>{` and `}<inlineCode parentName="p">{`extractFromCallIfValid`}</inlineCode>{`.`}</p>
    <h2 {...{
      "id": "icu-message-format-parsing"
    }}>{`ICU Message Format Parsing`}</h2>
    <p>{`A key feature of Stele is automatically creating ICU messages from JSX chunks.
This is achieved by representing nested JSX elements as XML-like tags. As long
as the translated ICU message's tags form the same tree shape as the source ICU
message, Stele can reliably combine the original JSX tree with the translated
ICU message into a translated JSX tree. This, in turn, is achieved by parsing an
ICU message into an AST.`}</p>
    <p>{`The library for parsing an ICU message, `}<inlineCode parentName="p">{`intl-messageformat-parser`}</inlineCode>{`, refers to
each node in the AST as an "element". This is because it is common for an ICU
message to have multiple root nodes and a depth of one. The only time a tree can
be deeper then one level is when a `}<inlineCode parentName="p">{`{select}`}</inlineCode>{` format, a `}<inlineCode parentName="p">{`{plural}`}</inlineCode>{` format, or a
`}<inlineCode parentName="p">{`{selectordinal}`}</inlineCode>{` format is used.`}</p>
    <p>{`As `}<inlineCode parentName="p">{`intl-messageformat-parser`}</inlineCode>{` evolves, the AST has become more sophisticated.
For example, version `}<inlineCode parentName="p">{`3.6.0`}</inlineCode>{` added a `}<inlineCode parentName="p">{`PoundElement`}</inlineCode>{` to support escaping the `}<inlineCode parentName="p">{`#`}</inlineCode>{`
sign inside of a `}<inlineCode parentName="p">{`{plural}`}</inlineCode>{`. This meant that Stele needed to be updated to
support that too.`}</p>
    <p>{`In order to understand the XML-like tags inside of an ICU message, Stele
performs parsing and book-keeping of its own. The bad news is that this is
fragile and complicates both the extraction process and the replacement process.
The good news is that since version `}<inlineCode parentName="p">{`4.0.0`}</inlineCode>{`, `}<inlineCode parentName="p">{`intl-messageformat-parser`}</inlineCode>{` can
parse XML-like tags, which is represented by `}<inlineCode parentName="p">{`TagElement`}</inlineCode>{`.`}</p>
    <p>{`Solving this tech debt entails upgrading `}<inlineCode parentName="p">{`intl-messageformat-parser`}</inlineCode>{` to version
`}<inlineCode parentName="p">{`4.0.0`}</inlineCode>{` or newer, as well as migrating Stele's tag handling to take advantage of
`}<inlineCode parentName="p">{`TagElement`}</inlineCode>{`.`}</p>
    <h2 {...{
      "id": "statefulness-and-initialization"
    }}>{`Statefulness and Initialization`}</h2>
    <p>{`Stele performs two main functions: extraction of messages in a codebase, and
replacement of messages when building non-English bundles of the codebase. These
two functions exhibit very different state change patterns:`}</p>
    <ul>
      <li parentName="ul">{`Extraction gradually builds up multiple messages as more and more files are
processed, culminating in a final exportation step when all files are
processed away. With an AST visitor function as the focal point, extraction
is very stateful.`}</li>
      <li parentName="ul">{`Replacement, in contrast, loads an entire catalog on launch, and uses that
information as a lookup table for replacing text. With the visitor function
as the focal point, replacement is more or less stateless.`}</li>
    </ul>
    <h3 {...{
      "id": "singleton"
    }}>{`Singleton`}</h3>
    <p>{`All instantiated instances of Stele share the same catalog. This effectively
makes Stele a module-level singleton, which means once Stele is `}<inlineCode parentName="p">{`require`}</inlineCode>{`'d, the
only way to get back to an initial state is to call the exported function
`}<inlineCode parentName="p">{`resetMessages`}</inlineCode>{`.`}</p>
    <p>{`This makes it harder to do various tasks, from more obvious ones like having
multiple instances of Stele in the same Node process, to less obvious ones like
unit-testing Stele and managing the lifecycle of instantiating the catalog.`}</p>
    <h3 {...{
      "id": "splitting"
    }}>{`Splitting`}</h3>
    <p>{`Instead of keeping Stele as a single plug-in that runs in either extraction mode
or replacement mode, we could split Stele into two modules, one specifically for
extraction, the other for replacement.`}</p>
    <p>{`For the purposes of extraction, Stele does not even need to run as a Babel
plugin. Babel's own API is rich enough such that Stele could effectively use it
to parse a set of source files without the need of any transforms / emit.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      